
const drawer = document.querySelector("#drawer")
const close = document.querySelector("#close")
const nav = document.querySelector("#nav")

drawer.addEventListener("click", () => {
    nav.classList.add("open")
})

close.addEventListener("click", () => {
    nav.classList.remove("open")
})
